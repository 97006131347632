
import React, { Component } from 'react';
import { Body1 } from 'osu-react-components';
import '../Body.css';

class Body extends Component {
  constructor() {
    super();
    this.state = {
      hideContent: false,
      allowCollapse: false,
      maxHeight: 60,
      contentHeight: 0,
      fromClickEvent: false
    }
  }

  scrubHtml(data) {
    const allowedTags = [
      "br",
      "a",
      "b",
      "i",
      "ul",
      "li",
      "u",
      "span",
      "div",
      "p"
    ]

    let readAheadFlag = false;
    let buffer = "";
    let startPos;
    let endPos;
    let endBuffer = "";
    let lastPos = 0;
    for (var i = 0; i < data.length; i++) {
      if (data.charAt(i) === ">") {
        readAheadFlag = false;
        endPos = i;
        buffer += data.charAt(i);
        let found = false;
        for (let tag of allowedTags) {
          if (buffer.startsWith("<" + tag) || buffer.startsWith("</" + tag))
            found = true;
        }
        if (lastPos === 0) {
          lastPos--;
        }
        if (found === true) {
          endBuffer += data.substring(lastPos + 1, endPos + 1);
        }
        else {
          endBuffer += data.substring(lastPos + 1, startPos);
        }

        lastPos = endPos;
        buffer = "";
        startPos = undefined;
        endPos = undefined;
      }
      if (readAheadFlag === true) {
        buffer += data.charAt(i);
      }
      if (data.charAt(i) === "<") {
        readAheadFlag = true;
        startPos = i;
        buffer += data.charAt(i);
      }
    }

    return endBuffer;
  }

  render() {
    let postingDescription;
    if (this.props.postingDescription && this.props.postingDescription.length > 0) {
      postingDescription = this.scrubHtml(this.props.postingDescription);
    }
    else {
      postingDescription = this.props.postingDescription;
    }
    return <div>
      <Body1>You were selected to give a job reference on behalf of {this.props.candidateName}, applying to The Ohio State University, for the position of {this.props.jobName}.</Body1>
      <br />
      <Body1>The Ohio State University sets the stage for academic achievement and innovation. It's where friendships are forged, tradition is brought to life and a better global community is built. Our mission is as clear today as it was 150 years ago: to illuminate a pathway to education, research and health care that creates vibrant futures.</Body1>
      <br />
      <Body1>Please contact <a href="mailto:HRConnection@osu.edu">HRConnection@osu.edu</a> with any questions or concerns.</Body1>
      <br />
      {this.props.postingDescription !== '' && <Body1 className="mt-3">
        <h2 className="mb-1 typography typography-body1" color="gray" >Job Description:</h2>
        <span dangerouslySetInnerHTML={{ __html: postingDescription }} />
      </Body1>}
      <br />
    </div>;
  }
}

export default Body;




