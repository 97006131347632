import React from 'react';
import {Icon} from 'osu-react-components';

class UploadDisplay extends React.Component {

  renderFileUpload = (uploadedFile, i) => {
    const {
      file,       // file descriptor from the upload
    } = uploadedFile

    return (
      <div key={i}>
        <p>{file.name}</p>
        <br />
      </div>
    )
  }

  render() {
    const { uploadedFiles, progress } = this.props
    return (
      <div className='dropZoneBox'> 
      <Icon type='cloud-upload' size='5x' color='gray'/>
        <br />        
        {uploadedFiles.map(this.renderFileUpload)}
        {progress ? (<div className="rdsu-progress">{progress}</div>) : null}
      </div>
    )
  }
}


export default UploadDisplay;