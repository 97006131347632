import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import configureStore from './storeConfig';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css'; 
import * as serviceWorker from './serviceWorker';
import AppContainer from './App/containers/AppContainer';
import './index.css';

const store = configureStore();

ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
        <AppContainer />
    </BrowserRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
