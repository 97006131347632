import {
    REFERENCE_REQUEST_IS_LOADED,
    REFERENCE_REQUEST_IS_LOADING,
    REFERENCE_REQUEST_HAS_ERROR,
    REFERENCE_SUBMIT_IS_SUCCESS,
    REFERENCE_SUBMIT_HAS_ERROR,
    REFERENCE_SUBMIT_IS_LOADING
} from '../actions-index';

export function referenceRequest(state = "", action) {
    switch (action.type) {
        case REFERENCE_REQUEST_IS_LOADED:
            return action.payload;
        default:
            return state;
    }
}

export function referenceRequestIsLoading(state = true, action) {
    switch (action.type) {
        case REFERENCE_REQUEST_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}



export function referenceRequestHasError(state = null, action) {
    switch (action.type) {
        case REFERENCE_REQUEST_HAS_ERROR:
            return action.payload;
        default:
            return state;
    }
}

export function referenceSubmitIsSuccess(state = null, action) {
    switch (action.type) {
        case REFERENCE_SUBMIT_IS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function referenceSubmitHasError(state = null, action) {
    switch (action.type) {
        case REFERENCE_SUBMIT_HAS_ERROR:
            return action.payload;
        default:
            return state;
    }
}

export function referenceSubmitIsLoading(state = null, action) {
    switch (action.type) {
        case REFERENCE_SUBMIT_IS_LOADING:
            return action.payload;
        default:
            return state;
    }
}



