import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import Form from '../../Form/components/Form';
import {validate} from '../../Form/components/Form';

import { setReferenceSubmit } from '../../actions-index';
import { reduxForm, getFormValues } from 'redux-form';

const mapStateToProps = (state, ownProps) => {
    
    return {       
        referenceSubmitIsSuccess: state.referenceSubmitIsSuccess,
        referenceSubmitHasError: state.referenceSubmitHasError,
        referenceSubmitIsLoading: state.referenceSubmitIsLoading,
        formValues: getFormValues('assesmentForm')(state),
        ownProps
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReferenceSubmit: (answers) => dispatch(setReferenceSubmit(answers))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'assesmentForm', validate})(Form)));

