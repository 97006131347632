import React, { Component } from 'react';
import  {Body1, Heading6}  from 'osu-react-components';
import logo from '../../logo.png';

class Success extends Component {
    render() {
      return  <div className="errorOrLoadingText">
      <img src={logo} alt='OSU Logo' className='logo' />
          <Heading6>Thank you for your submission!</Heading6>
          <Body1>If you would like to contact someone for further action,<br/> please contact HR Connection at 
                    <br /><div className="contact">Phone: <a href="tel:+6142476947">(614)-247-6947</a>
                    <br />Email: <a href="mailto:HRConnection@osu.edu">HRConnection@osu.edu</a></div> </Body1>
      </div>;
    }
}

export default Success;
                   
                   
                   
                   
                   