

import React, { Component } from 'react';
import '../Form.css';
import QuestionForm from './QuestionForm';
import LetterUploadForm from './LetterUploadForm';
import Submit from '../../Submit/components/Submit';
import { Body1 } from 'osu-react-components';
import { Field } from 'redux-form';

export const validate = (values, prevprops) => {
    console.log("validating2....", values)
    const errors = {}
    let quizStarted = false;
    for (var key in values) {
        if (((key.includes('-') && !values[key].target && values[key] !== false) || key === "additionalComments") || key === "fileUpload") {
            quizStarted = true;
        }
    }

    if (values.OptOut && quizStarted) {
        errors.OptOut = 'Opt Out cannot be selected while also selecting answers';
    }

    if (values.fileUpload && values.additionalComments) {
        errors.additionalComments = "Please ONLY upload a file or write a recommendation in the box";
    }

    var len = Object.keys(values);
    len.forEach(function (elem) {
        if (values[elem] === false) {
            delete values[elem];
        }
    });
    return errors
}

class Form extends Component {
    constructor(props) {
        super(props);
        this.submitQuiz = this.submitQuiz.bind(this);
        this.renderOptOut = this.renderOptOut.bind(this);
        this.fileOnChange = this.fileOnChange.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.questionFormRef = React.createRef();
        this.state = {
            file: null,
            isUploading: false
        }
    }

    componentDidUpdate(prevProps) {
        console.log(this.props);
        if (this.state.isClearing || (this.props.certify === false && prevProps.certify === true)) {
            this.setState({ isClearing: false });
            this.questionFormRef.current.focus();
            console.log("resetting focus");
        }
    }


    componentDidMount() {
        console.log("component did mount");
    }

    toggleIsUploading = (value) => {
        this.setState({ isUploading: value });
    }

    clearForm = (event) => {
        event.preventDefault();
        this.props.destroy();
        this.setState({ isClearing: true, fileError: undefined });
    }

    submitQuiz = (values) => {
        for (let val in values) {
            if (typeof (values[val]) === "object") { //textareas in redux-form can turn into objects if something is entered and then deleted
                delete values[val];
            }
        }

        console.log('values: ' + JSON.stringify(values));

        var questionResponses = {};
        questionResponses.type = 'FORM';
        questionResponses.responses = {};
        questionResponses.token = sessionStorage.getItem('token');

        if (this.props.referenceRequest.referenceType === 'FORM') {
            console.log("values:", values);
            for (var property in values) {
                console.log('-->', values[property]);
                if (property.includes('-')) {


                    let qSplit = property.split("-");
                    let questionID = qSplit[0];
                    let questionAnswerID = qSplit[1];

                    if (typeof (values[property]) === "boolean") //it's a checkbox
                    {
                        values[property] = questionAnswerID + "-" + values[property];
                    }
                    let aSplit = values[property].split("-");
                    let answerID;
                    if (questionAnswerID !== "") {
                        answerID = aSplit[0];
                    }
                    else {
                        answerID = values[property];
                    }

                    //get the question object based on questionID
                    let questionObj = this.props.referenceRequest.form.questions.find((elem) => {
                        return elem.id === questionID
                    });

                    let answerObj = null;
                    if (questionAnswerID !== "") { //as in it has answers and is not freeform
                        answerObj = (aSplit.length > 1) ? this.props.referenceRequest.form.questions.find((elem) => {
                            return elem.id === questionID
                        }).answers.find((elem) => {
                            return elem.answerId == aSplit[0]
                        }) : null;
                    }

                    let builder = {
                        questionText: questionObj.questionText,
                        answers: [{
                            answerID: (aSplit.length === 1) ? null : answerID,
                            answerText: (answerObj === null) ? answerID : answerObj.answerText,
                        }]
                    }
                    if (questionResponses.responses[questionID] !== undefined) {
                        questionResponses.responses[questionID].answers.push(builder.answers[0])
                    }
                    else {
                        questionResponses.responses[questionID] = builder;
                    }
                }
            }

            //sort according to original layout
            let sortedAnswers = [];
            for (let original of this.props.referenceRequest.form.questions) {
                let answer = questionResponses.responses[original.id];
                if (answer) {
                    sortedAnswers.push(answer);
                }
            }

            questionResponses.responses = sortedAnswers;
            questionResponses.optOut = values.OptOut;
            console.log("questionReponses:", questionResponses)

            this.props.setReferenceSubmit(questionResponses);
        }
        else {
            console.log("values:", values);
            let answers = { type: "LETTER" };
            if (values.fileUpload) {
                console.log("VALUES:", values.fileUpload);
                answers.filename = values.fileUpload;
                //answers.filename = answers.filename.replace(/[^\w\d_\-.]+/ig, '')
            }
            if (values.additionalComments) {
                answers.letter = values.additionalComments;
            }

            if (values.OptOut) {
                answers.optOut = values.OptOut
            }
            console.log("answers:", answers);
            this.props.setReferenceSubmit(answers);
        }
    }

    fileOnChange = (event) => {
        console.log("event:", event);
        this.setState({ file: event.target.files[0] });
    }

    renderOptOut = (field) => {
        const {
            input,
            disabled
        } = field;


        return (<Body1>
            <label htmlFor="notRespond" className="checkbox-inline typography typography-body1"><input type="checkbox" id="notRespond" {...input} value="" onBlur={() => input.onBlur(input.value)} disabled={disabled} /> Select if you prefer not to respond</label>
        </Body1>);
    }

    handleFileError = (error) => {
        this.setState({ fileError: error });
        console.log("setting fileError: ", error);
    }

    countAnswers = (answers) => {
        let uniqueKeys = [];
        for (let key in answers) {
            let splitValue = key.split('-');
            if (!uniqueKeys.includes(splitValue[0])) {
                if (typeof (answers[key]) !== "object")
                    uniqueKeys.push(splitValue[0]);
            }
        }

        return uniqueKeys.length;
    }

    render() {
        console.log("Form stuff:", this.props);
        let formValuesEmpty = true;
        if (this.props.formValues && Object.entries(this.props.formValues).length > 0)
            formValuesEmpty = false;
        console.log("formValuesEmpty: ", formValuesEmpty);
        let questionCount = 0;
        let answerCount = 0;
        if (this.props.referenceRequest.form) {
            questionCount = this.props.referenceRequest.form.questions.length;
            answerCount = this.countAnswers(this.props.formValues);
            console.log("questionCount: ", questionCount);
            console.log("answerCount: ", answerCount);
        }

        let optOutBox = <Field
            name="OptOut"
            index="2"
            component={this.renderOptOut}
            disabled={this.props.certify ? true : false}
        />;

        let errors = [];
        if (this.props._reduxForm) {
            console.log("errors: ", this.props._reduxForm.syncErrors);
            for (let err in this.props._reduxForm.syncErrors) {
                console.log("err is: ", err);
                // if(this.props._reduxForm.syncErrors[err] !== "Please answer all questions in the form" && this.props.certify === true)
                errors.push(this.props._reduxForm.syncErrors[err]);
            }
        }

        console.log("fileError: ", this.state.fileError);
        if (this.state.fileError) {
            errors.push(this.state.fileError);
        }

        return (<div tabIndex="-1" id="page-content" ref={this.questionFormRef}>
            <form onSubmit={this.props.handleSubmit(this.submitQuiz)}>
                <div className={this.props.certify ? "certifying" : ""}>
                    {!this.state.isClearing && <div>
                        {(this.props.referenceRequest.referenceType === 'FORM') ?
                            (<div><h3 className="typography typography-body1">Please answer these questions:</h3>
                                <br /><QuestionForm formData={this.props.referenceRequest.form} touch={this.props.touch} change={this.props.change} isDisabled={this.props.certify} handleClick={this.props.handleClick} isClearing={this.state.isClearing} /></div>)
                            :
                            (<div><h3 className="typography typography-body1">Please do one of the following:</h3>
                                <br /><LetterUploadForm isDisabled={this.props.certify} change={this.props.change} handleFileError={this.handleFileError} handleClick={this.props.handleClick} fileOnChange={this.fileOnChange} isUploading={this.state.isUploading} toggleIsUploading={this.toggleIsUploading} /></div>)}
                        <br />
                        {optOutBox}</div>}
                </div>
                <br />
                <span className="validation" role="alert">
                    {errors.length > 0 && errors.map((error, errorIndex) => {
                        return <p key={error}>{error}</p>
                    })
                    }
                </span>
                <p aria-hidden="true" id="required-description">
                    <span className="required">*</span>Required field</p>
                <Submit isOptOut={this.props.formValues && this.props.formValues.OptOut} questionCount={questionCount} answerCount={answerCount} handleClick={this.props.handleClick} certify={this.props.certify} isPristine={formValuesEmpty} isUploading={this.state.isUploading} clear={this.clearForm} isInvalid={(errors.length > 0)} />
                <br />
            </form>
        </div>);
    }
}

export default Form;