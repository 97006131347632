import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import App from '../components/App';
import { setReferenceRequest } from '../actions';
import { reduxForm } from 'redux-form';

const mapStateToProps = (state, ownProps) => {
    return {
        referenceRequest: state.referenceRequest,
        referenceRequestIsLoading: state.referenceRequestIsLoading,
        referenceRequestHasError: state.referenceRequestHasError,
        referenceSubmitIsSuccess: state.referenceSubmitIsSuccess,
        referenceSubmitHasError: state.referenceSubmitHasError,
        referenceSubmitIsLoading: state.referenceSubmitIsLoading,
        ownProps
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setReferenceRequest: (token) => dispatch(setReferenceRequest(token))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'assesmentForm' })(App)));

