import React, { Component } from 'react';
import OSUNavBar from 'react-osu-navbar';
import { OSULoading } from 'osu-react-components';
import '../App.css';
import Header from '../../Header/components/Header';
import Body from '../../Body/components/Body';
import Error from '../../Error/components/Error';
//import Loading from '../../Loading/components/Loading';
import Success from '../../Success/components/Success';
import FormContainer from '../../Form/containers/FormContainer';

class App extends Component {
  constructor(props) {
    super(props);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    sessionStorage.setItem('token', token);  

    this.state = {
      certify: false
    }
  }

  componentDidMount() {

    if (!Object.keys(this.props.referenceRequest).length) {
      this.props.setReferenceRequest(sessionStorage.getItem('token'));
    }

    this.setState({ certify: false });
  }

  componentDidUpdate() {
    if(this.state.certify)
      this.scrollToBottom();    
  }

  scrollToBottom() {
    window.scrollTo(0,document.body.scrollHeight);
  }

  handleClick = (item) => {
    switch (item) {
      case "submit":
        this.setState({ certify: true });            
        break;
      case "edit":
        this.setState({ certify: false });
        break;    
      default:
        this.setState({ certify: false });
        break;
    }
  }


  render() {
    if (this.props.referenceRequestHasError && this.props.referenceRequestHasError.data) {
      console.log("props:", this.props.referenceRequestHasError.data.message);

    }
    return (
      <div className="App">
        <OSUNavBar />
        {this.props.referenceRequestIsLoading || this.props.referenceSubmitIsLoading ? <OSULoading className='loading' /> :  <div>
        {this.props.referenceRequestHasError && <Error error={this.props.referenceRequestHasError} />}
        {this.props.referenceSubmitHasError && <Error error={this.props.referenceSubmitHasError} />}
        {this.props.referenceSubmitIsSuccess && <Success />}            
        {(this.props.referenceRequest && !this.props.referenceSubmitIsSuccess && !this.props.referenceSubmitHasError && !this.props.referenceRequestHasError) &&
          <div className="app-body no-gutters">            
            <main className="col-sm-11 col-12">
              <Header user={this.props.referenceRequest.name} />
              <Body candidateName={this.props.referenceRequest.candidateName} jobName={this.props.referenceRequest.jobName}
                postingDescription={this.props.referenceRequest.postingDescription} />
              <hr />
              <FormContainer referenceRequest={this.props.referenceRequest} scrollToBottom={this.scrollToBottom} handleClick={this.handleClick} certify={this.state.certify}
              handleDrop={this.handleDrop} handleDrag={this.handleDrag} handleDragEnd={this.handleDragEnd} file={this.state.file} />
            </main>            
          </div>
        }
        <div ref={endScreen => {this.endScreen = endScreen;}} />
      </div>}</div>
    );
  }
}
// questionFormRef={ref => (this.questionFormRef = ref)}
export default App;
