
import React, { Component } from 'react';
import { OSUButton, Body1 } from 'osu-react-components';
import '../Submit.css';

class Submit extends Component {
  constructor(props) {
    super(props);
    this.certifyNameChange = this.certifyNameChange.bind(this);
    this.state = {
      boxEmpty: true,
      boxValue: ''
    }
  }

  certifyNameChange = (event) => {
    if (event.target.value !== '')
      this.setState({ boxEmpty: false, boxValue: event.target.value });
    else
      this.setState({ boxEmpty: true, boxValue: event.target.value });
  }

  render() {
    if (!this.props.certify) {
      return <div>
      <OSUButton color='gray' onClick={this.props.clear}>Clear</OSUButton>
      <OSUButton color='blue' disabled={this.props.isUploading || this.props.isPristine || this.props.isInvalid || (this.props.questionCount !== this.props.answerCount && !this.props.isOptOut)} onClick={() => this.props.handleClick("submit")}>Submit</OSUButton>
      </div>
    }
    else {      
      return <div>
       <Body1></Body1>
       <label htmlFor="signatureField" className="typography typography-body1" >Please type your name in the box to certify your reference</label>
       <br />
       <input id="signatureField" type="textbox" autoFocus value={this.state.boxValue} className="col-md-4 col-sm-3" onChange={this.certifyNameChange} />
        <br />
        <OSUButton color='blue' onClick={() => this.props.handleClick("edit")}>Edit</OSUButton> <OSUButton type="submit" disabled={this.state.boxEmpty}>Certify</OSUButton>
      </div>
    }
  }
}

export default Submit;




