import { REFERENCE_REQUEST_IS_LOADED, REFERENCE_REQUEST_IS_LOADING, REFERENCE_REQUEST_HAS_ERROR, REFERENCE_SUBMIT_IS_SUCCESS, REFERENCE_SUBMIT_IS_LOADING, REFERENCE_SUBMIT_HAS_ERROR } from "../actions-index";
import axios from "axios";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function referenceRequestIsLoading(isLoading) {
    return {
        type: REFERENCE_REQUEST_IS_LOADING,
        payload: isLoading
    }
}

export function referenceRequestIsLoaded(user) {
    return {
        type: REFERENCE_REQUEST_IS_LOADED,
        payload: user
    }
}

export function referenceRequestHasError(err) {
    return {
        type: REFERENCE_REQUEST_HAS_ERROR,
        payload: err
    }
}

export function referenceSubmitIsSuccess(quizResult) {
    return {
        type: REFERENCE_SUBMIT_IS_SUCCESS,
        payload: quizResult
    };
}

export function referenceSubmitHasError(err) {
    return {
        type: REFERENCE_SUBMIT_HAS_ERROR,
        payload: err
    };
}

export function referenceSubmitIsLoading(isLoading) {
    return {
        type: REFERENCE_SUBMIT_IS_LOADING,
        payload: isLoading
    };
}

export function setReferenceSubmit(quizAnswers) {
    return (dispatch) => {
        dispatch(referenceSubmitIsLoading(true));
        let token = sessionStorage.getItem('token');
        if (quizAnswers.type==='LETTER') { //post text letter
            //let formData = new FormData();
            //formData.append("File", quizAnswers.file);
             axios.post(`${BASE_URL}/referenceDetails/letter/${token}`, quizAnswers, {

                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log("sent");
                    dispatch(referenceSubmitIsSuccess(true));
                }).catch((err) => {
                    console.log("ERROR IS :", err);
                    if(err.response)
                        dispatch(referenceSubmitHasError(err.response));
                    else
                        dispatch(referenceSubmitHasError({status: 500}));
                }).finally(() => {
                    dispatch(referenceSubmitIsLoading(false));
                });
        }
        else if(quizAnswers.type==='FORM'){  //submit the questionnaire
            axios.post(`${BASE_URL}/referenceDetails/form/${token}`, quizAnswers, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    console.log("sent");
                    dispatch(referenceSubmitIsSuccess(true));
                })
                .catch((err) => {
                    console.log("ERROR IS :", err);
                    if(err.response)
                        dispatch(referenceSubmitHasError(err.response));
                    else
                        dispatch(referenceSubmitHasError({status: 500}));
                })
                .finally(() => {
                    dispatch(referenceSubmitIsLoading(false));
                });
        }
        else{
            console.log("invalid type");
            throw new Error('invalid submission type');
        }

    }
}


export function setReferenceRequest() {

    let token = sessionStorage.getItem('token');
    console.log("action token:", token);

    return function (dispatch) {
        dispatch(referenceRequestIsLoading(true));

        if(!token || token === 'null'){
            dispatch(referenceRequestHasError({status:404}));
            dispatch(referenceRequestIsLoading(false));

        }else{
            return axios.get(`${BASE_URL}/referenceDetails/${token}`)
            .then(response => {
                if (response) {
                    //response.data.referenceType = "LETTER";
                    //response.data.form.questions[0].answers = null;
                    dispatch(referenceRequestIsLoaded(response.data));
                }
            }).catch((err) => {
                console.log("ERROR IS :", err.response);
                if(err.response) {
                    dispatch(referenceRequestHasError(err.response));
                }
                else {
                    dispatch(referenceRequestHasError("An unknown error has occurred."))
                }
            }).finally(() => {
                dispatch(referenceRequestIsLoading(false));
            });
        }
    }
}
