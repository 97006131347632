import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { referenceRequest, referenceRequestIsLoading, referenceRequestHasError, referenceSubmitIsSuccess, referenceSubmitHasError, referenceSubmitIsLoading } from './App/reducers';


export default combineReducers({
    form: formReducer,
    referenceRequest,
    referenceRequestIsLoading,
    referenceRequestHasError,
    referenceSubmitHasError,
    referenceSubmitIsSuccess,
    referenceSubmitIsLoading
})
