import React, { Component } from 'react';
import '../LetterUploadForm.css';
import { Field } from 'redux-form';
import { Body1 } from 'osu-react-components';
import ReactS3Uploader from 'react-s3-uploader';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import UploadDisplay from './UploadDisplay';
import { BASE_URL } from '../../App/actions';

class LetterUploadForm extends Component {
    constructor(props) {
        super(props);
        //this.renderLetterQuestion = this.renderLetterQuestion.bind(this);
        this.renderS3Uploader = this.renderS3Uploader.bind(this);
        this.renderDropZone = this.renderDropZone.bind(this);
        this.renderTypedQuestion = this.renderTypedQuestion.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
        this.preprocessFile = this.preprocessFile.bind(this);

        this.uploadRef = React.createRef();

        this.state = {
            uploadProgress: 0,
            isUploadError: false
        }
    }

    updateProgress(value) {
        if (value === 100) {
            this.setState({ uploadProgress: 0 })
            this.props.toggleIsUploading(false);
            this.setState({ isUploadError: false });
            this.props.handleFileError("");
        }
        else {
            this.setState({ uploadProgress: value });
            this.props.toggleIsUploading(true);
        }
        console.log("progess: " + value);
    }

    preprocessFile(file, next) {
        let acceptedFileTypes = [
            ".bmp:",
            ".csv",
            ".doc",
            ".docx",
            ".gif",
            ".htm",
            ".html",
            ".jpeg",
            ".jpg",
            ".pdf",
            ".png",
            ".ppt",
            ".ppix",
            ".svg",
            ".tif",
            ".tiff",
            ".xls",
            ".xlsm",
            ".xlsx",
            ".xtlt",
            ".xltx",
            ".xml",
            ".zip",
            ".txt",
            ".rtf"
        ];

        let found = false;
        acceptedFileTypes.forEach(function (fileType) {
            if (file.name.endsWith(fileType)) {
                found = true;
            }
        });

        if (!found) {
            this.setState({
                updateProgress: 0,
                isUploadError: true
            });

            this.props.handleFileError("File type is not supported, please try another file");

            this.uploadRef.current.clear();
            return false;
        }

        if (file.size > 5242880) //5mb to fit the lambda invoke limit
        {
            this.setState({
                updateProgress: 0,
                isUploadError: true
            });

            this.props.handleFileError("File must be 5mb or less");

            this.uploadRef.current.clear();
            return false;
        }

        this.props.handleFileError(undefined);

        next(file);
    }

    renderDropZone(field) {
        const { type } = field;
        let token = sessionStorage.getItem('token');
        const uploadOptions = {
            signingUrl: '/signedUrl/' + token,
            server: BASE_URL,
            uploadRequestHeaders: {}
        }

        const s3Url = ''

        return (
            <DropzoneS3Uploader
                name={field.input.name}
                type={type}
                onFinish={(filesToUpload, e) => { field.input.onChange(filesToUpload.file) }}
                upload={uploadOptions}
                onDrop={(x) => { return this.onDrop }}
                onDropRejected={(x) => alert('File is too large, please limit file sizes to 10mb.')}
                notDropzoneProps={['onFinish', 's3Url', 'filename', 'host', 'upload', 'isImage', 'notDropzoneProps', 'onDrop']}
                s3Url={s3Url}
                maxSize={1024 * 1024 * 10} //this is 10mb
                multi='false'
            >
                <UploadDisplay />
            </DropzoneS3Uploader>

        );

    }

    renderS3Uploader(field) {
        const {
            input,
            type,
            disabled,
            meta: { error, warning }
        } = field;
        let token = sessionStorage.getItem('token');

        var comp = (
            <ReactS3Uploader
                signingUrl={"/signedUrl/" + token}
                signingUrlMethod="GET"
                name={input.name}
                type={type}
                ref={this.uploadRef}
                onChange={this.props.fileOnChange}
                preprocess={this.preprocessFile}//{this.onUploadStart}
                onSignedUrl={this.onSignedUrl}
                onProgress={this.updateProgress}//{this.onUploadProgress}
                onError={this.onUploadError}
                onFinish={(s3Url, file) => { field.input.onChange(file.name) }}
                uploadRequestHeaders={{}}
                contentDisposition="auto"
                server={BASE_URL}
                autoUpload={true}
                disabled={disabled}
            />
        )

        return (
            <div>
                {comp}
                {((error && <span className="validation">{error}</span>) ||
                    (warning && <span>{warning}</span>))}
            </div>
        )
    }

    renderTypedQuestion(field) {
        const {
            input,
            disabled,
            meta: { error, warning }
        } = field;

        var comp = (
            <div>
                <label htmlFor="typedAnswer" className="typography typography-body1">Type Your Recommendation</label>
                <textarea id="typedAnswer" className='answerBox col-12' {...input} placeholder='type your recommendation...' disabled={disabled}></textarea>
            </div>
        )

        return (
            <div>
                {comp}
                {((error && <span className="validation">{error}</span>) ||
                    (warning && <span>{warning}</span>))}
            </div>
        )
    }

    render() {
        let additionalComments = <Field
            name="additionalComments"
            index="1"
            component={this.renderTypedQuestion}
            disabled={this.props.isDisabled}
        />;
        let letterQuestion = <Field
            name="fileUpload"
            //type="file"
            index="0"
            //component={this.renderLetterQuestion}
            // component={this.renderS3Uploader}
            component={this.renderS3Uploader}
            //component={this.renderDropZone}
            disabled={this.props.isDisabled ? true : false}
        />;

        if (this.props._reduxForm) {
            let errors = this.props._reduxForm.syncErrors;
            console.log("errors", errors);
        }

        return (
            <div>
                <Body1>Upload your letter of recommendation by dropping a reference letter or clicking to upload below:</Body1>
                {letterQuestion}
                {(this.state.uploadProgress > 0) && <div className="progressBar">
                    <div style={{ width: this.state.uploadProgress + "%" }}></div>
                </div>}
                {/* {(this.state.isUploadError) && <p className="validation">{this.state.fileUploadError}</p>} */}
                <br />
                <Body1>Or</Body1>
                <br />
                {additionalComments}
            </div>
        );
    }
}

export default LetterUploadForm;