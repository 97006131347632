
import React, { Component } from 'react';
import { Body1 } from 'osu-react-components';
import '../Error.css';
import logo from '../../logo.png';

class Error extends Component {
    render() {
        return <Body1 className='errorOrLoadingText'>
            {(this.props.error.status === 401 || this.props.error.status === 403) && <div>
                <img src={logo} alt='OSU Logo' className='logo' />
                <h1 className='formHeadings'>Reference input has already been submitted</h1>
                <Body1>If you would like to contact someone for further action,<br/> please contact HR Connection at 
                    <br /><div className="contact">Phone: <a href="tel:+6142476947">(614)-247-6947</a>
                    <br />Email: <a href="mailto:HRConnection@osu.edu">HRConnection@osu.edu</a></div> </Body1>
            </div>}
            {(this.props.error.status === 404 )  && <div>
                <img src={logo} alt='OSU Logo' className='logo' />
                <h1 className='formHeadings'>Reference request was not found.</h1>
                <Body1>If you would like to contact someone for further action,<br/> please contact HR Connection at 
                <br /><div className="contact">Phone: <a href="tel:+6142476947">(614)-247-6947</a>
                    <br />Email: <a href="mailto:HRConnection@osu.edu">HRConnection@osu.edu</a></div> </Body1>
            </div>}
            {(this.props.error.status === 500)  && <div>
                <img src={logo} alt='OSU Logo' className='logo' />
                <h1 className='formHeadings'>There was an error processing your request</h1>
                <Body1>If you would like to contact someone for further action,<br/> please contact HR Connection at 
                <br /><div className="contact">Phone: <a href="tel:+6142476947">(614)-247-6947</a>
                    <br />Email: <a href="mailto:HRConnection@osu.edu">HRConnection@osu.edu</a></div> </Body1>
            </div>}
        </Body1>;
    }
}

export default Error;




