import React, { Component } from 'react';
import '../QuestionForm.css';
import { Field } from 'redux-form';
import { Body1 } from 'osu-react-components';

class QuestionForm extends Component {

  constructor(props) {
    super(props);
    this.renderAnswer = this.renderAnswer.bind(this);
    this.renderRadioButton = this.renderRadioButton.bind(this);
    this.renderTextArea = this.renderTextArea.bind(this);
  }

  renderRadioButton(field) {
    const {
      input,
      disabled,
      question,
      answer
    } = field;

    console.log("question: ", question);
    console.log("answer: ", answer);

    return (
      <input id={`${question.id + '-' + 0}`} {...input} name={question.id} type="radio" onBlur={input.onBlur} disabled={disabled} aria-describedby="required-description" onChange={() => this.props.change(
        input.name, field.answer.answerId + '-' + field.answer.answerText)}/>
      
      );
  }

  renderCheckBox(field) {
    const {
      input,
      disabled,
      question,
      answer
    } = field;

    return (
      <input id={`${question.id + '-' + answer.answerId}`} {...input} name={question.id} type="checkbox" onBlur={input.onBlur} disabled={disabled} aria-describedby="required-description" >

      </input>
    );
  }


  renderTextArea(field) {
    const {
      input,
      disabled,
      question } = field;

    if (!this.props.isClearing) {
      if (typeof (input.value) !== "string") {
        input.value = "";
      }
    }

    return (
      <textarea className='answerBox col'
        placeholder='Type a response here...' id={`${question.id}-`} {...input} name={question.id} onBlur={input.onBlur} disabled={disabled} aria-describedby="required-description"
      ></textarea>
    );

  }

  renderAnswer(field) {
    const { input, answer, question, disabled } = field;
    var comp;
    if (question.questionType === "radio") {
      comp = (
        <label htmlFor={`${input.name}`} aria-labelledby={`${input.name}-label`}>
          <Field component={this.renderRadioButton} {...input} answer={answer} question={question} disabled={disabled} />
          <span className="mr-2"></span>
          <span id={`${input.name}-label`} className="middle">{answer.answerText}</span>
        </label>
      )
    }
    else if (question.questionType === "checkbox") {
      comp = (
        <label htmlFor={`${input.name}`} aria-labelledby={`${input.name}-label`}>
          <Field component={this.renderCheckBox} {...input} answer={answer} question={question} disabled={disabled} />
          <span className="mr-2"></span>
          <span id={`${input.name}-label`} className="middle">{answer.answerText}</span>
        </label>
      )
    }
    else {
      comp = (
        <div>
          <label htmlFor={`${input.name}`} aria-labelledby={input.name}></label>
          <Field component={this.renderTextArea} {...input} question={question} disabled={disabled} />
        </div>
      )
    }
    return (
      <div>
        {comp}
      </div>
    )
  }

  render() {
    console.log(this.props);
    let questionsJsx = "";
    if (this.props.formData) {
      questionsJsx = this.props.formData.questions.map((question, index) => {
        var answers;
        if (question.questionType === "checkbox") {
          answers = question.answers.map((answer, answerIndex) => {
            return <Field
              name={question.id + '-' + answer.answerId}
              index={answerIndex}
              answer={answer}
              key={answerIndex}
              question={question}
              component={this.renderAnswer}
              disabled={this.props.isDisabled ? true : false}
            />
          });
        }
        else if (question.questionType === "radio") {
          answers = question.answers.map((answer, answerIndex) => {
            return <Field
              name={question.id + '-' + 0}
              index={answerIndex}
              answer={answer}
              key={answerIndex}
              question={question}
              component={this.renderAnswer}
              disabled={this.props.isDisabled ? true : false}
            />
          });
        }
        else {
          answers = <Field
            name={(`${question.id}-`)}
            question={question}
            component={this.renderAnswer}
            type="text"
            disabled={this.props.isDisabled ? true : false}
          />
        }

        return (
          <div key={index}>
            <div id={question.questionId}>
              <fieldset>
                <legend className="typography typography-body1"><span aria-hidden="true" className="required">*</span>{question.questionText}</legend>
                {answers}
              </fieldset>
            </div>
            <br />
          </div>
        )
      }
      );
      if (this.props.isClearing) {
        this.props.toggleClear(false);
      }
    }
    return (
      <Body1>
        {questionsJsx}
      </Body1>
    );
  }
}

export default QuestionForm;
